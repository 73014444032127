import {Component} from '@angular/core'
import version from '../assets/package.json'

@Component({
  selector: 'spb-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})

export class AppComponent {
  title = 'Sparbanken Syd'
  public version = version.version
}
